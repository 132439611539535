import React, { useState, useContext } from "react"
import styled from "styled-components"
import Accordion from "../components/Accordion"
// import { Breadcrumbs } from "@components"
import { Button } from "@components/common/form"
import Seo from "@components/seo"
import {
  ErrorMessage,
  Input,
  Label,
  OutputLabel,
  TextArea,
  MultiSelect,
  SuccessMessage,
} from "@components/common/form"
import { MediumBody, MediumDisplay } from "../styles/typography"
import { integrationData } from "../data/integrationData"
import { FirebaseContext } from "../components/Firebase"

// import { doc, addDoc, collection } from "firebase/firestore";

const IntegrationPage = () => {
  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    integrate: [],
    needs: "",
    // createdAt: new Date(),
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (data.email !== data.confirmEmail) {
      setErrorMessage("Emails do not match")
    }
    if (!data.email.includes("@")) {
      return alert("Please enter a valid email")
    } else {
      firebase.db
        .collection("registrant")
        .add({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          confirmEmail: data.confirmEmail,
          integrate: data.integrate.label,
          needs: data.needs,
        })
        .then(() => {
          setSuccessMessage(
            `Hello, ${data.firstName}. Thank you for your interest in integrating with us. We will be in touch with you shortly.`
          )
        })
        .catch(error => {
          setErrorMessage(error.message)
        })

      // after a successful submission, reset the form values
      setTimeout(() => {
        setData({
          firstName: "",
          lastName: "",
          email: "",
          confirmEmail: "",
          integrate: [],
          needs: "",
        })
        // setErrorMessage("")
        // setSuccessMessage("")
      }, 3000)
    }
  }
  return (
    <>
      <Seo title="Integration" />
      <Container>
        <Banner>
          {/* <Breadcrumbs 
        to="/"
        page="Home"
        current="Integration"
      /> */}
          <MediumDisplay>bespoke integration</MediumDisplay>
          <BannerParagraph>
            we deliver bespoke process automation, and data-collection pipelines
            that improve operating margins and business scalability.
          </BannerParagraph>
        </Banner>
        <AccordionContainer>
          {integrationData.map((data, index) => {
            return (
              <Accordion
                key={index}
                title={data.title}
                description={data.description}
              />
            )
          })}
        </AccordionContainer>
        <FormContainer>
          <OutputLabel label="request access to bespoke integration" />
          <FormWrap onSubmit={handleSubmit}>
            <FormTopRow>
              <FlexItem>
                <Label htmlFor="first name">first name *</Label>
                <Input
                  name="first name"
                  placeholder="first name"
                  onChange={e => {
                    setData({
                      ...data,
                      firstName: e.target.value,
                    })
                  }}
                  value={data.firstName}
                  required
                />
              </FlexItem>
              <FlexItem>
                <Label htmlFor="last name">last name *</Label>
                <Input
                  name="last name"
                  placeholder="last name"
                  onChange={e => {
                    setData({
                      ...data,
                      lastName: e.target.value,
                    })
                  }}
                  value={data.lastName}
                  required
                />
              </FlexItem>
            </FormTopRow>
            <FormBottomRow>
              <FlexItem>
                <Label htmlFor="email">email *</Label>
                <Input
                  placeholder="email"
                  type="email"
                  onChange={e => {
                    setData({
                      ...data,
                      email: e.target.value,
                    })
                  }}
                  value={data.email}
                  required
                />
              </FlexItem>
              <FlexItem>
                <Label htmlFor="confirm email">confirm email *</Label>
                <Input
                  name="confirm email"
                  placeholder="confirm email"
                  onChange={e => {
                    setData({
                      ...data,
                      confirmEmail: e.target.value,
                    })
                  }}
                  value={data.confirmEmail}
                  required
                />
              </FlexItem>
            </FormBottomRow>
            <Label htmlFor="integrate">
              integrate *
              <MultiSelect
                name="integrate"
                isClearable={true}
                closeOnSelect={false}
                isSearchable={false}
                options={[
                  {
                    value: "dataCollection",
                    label: "data collection",
                  },
                  {
                    value: "processAutomation",
                    label: "business process automation",
                  },
                  {
                    value: "partner",
                    label: "partner on proprietary research",
                  },
                ]}
                onChange={e => {
                  setData({
                    ...data,
                    integrate: e,
                  })
                }}
                value={data.integrate}
              />
            </Label>
            <Label htmlFor="tell us your needs">
              tell us your needs
              <TextArea
                name="tell us your needs"
                placeholder="tell us your needs"
                onChange={e => {
                  setData({
                    ...data,
                    needs: e.target.value,
                  })
                }}
                value={data.needs}
              />
             {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              {!!successMessage && (
                <SuccessMessage>{successMessage}</SuccessMessage>
              )}
            </Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <NextButton type="submit">
                  submit
                  <ArrowRight
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <Path
                      d="M3.33334 7.99967H12.6667M12.6667 7.99967L8.00001 3.33301M12.6667 7.99967L8.00001 12.6663"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </ArrowRight>
                </NextButton>
              </div>
            {/* </div> */}
          </FormWrap>
        </FormContainer>
      </Container>
    </>
  )
}

export default IntegrationPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 680px;
  margin-inline: auto;
  padding: 0 20px;
`

const Banner = styled.section`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 24px 0;
  border-radius: 4px;
  min-height: 25vh;
`

const BannerParagraph = styled(MediumBody)`
  color: ${p => p.theme.bodyParagraphColor};
`
const AccordionContainer = styled.div`
  height: auto;
`
const FormContainer = styled.div`
  min-height: 100vh;
  padding: 5rem 0;
`

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  padding: 0 8px;
`

const FormTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`

const FormBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 24px; */
  gap: 24px;
`

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const NextButton = styled(Button)`
  margin-top: 1rem;
  justify-content: flex-end;
`

const Path = styled.path``

const ArrowRight = styled.svg`
  height: 16px;
  width: 16px;
  margin: 0.5rem;
  vertical-align: middle;

  ${Path} {
    stroke: ${p => p.theme.fillColor};
  }
`
