export const integrationData = [
    {
      title: "Data Collection",
      description: "implement automated and scalable data collection pipelines with zenith Business Process Automation"
    },
    {
      title: "Business Process Automation",
      description: "improve margins and operational scalability by automating core business processes with zenith"
    },
    {
      title: "Partner on proprietary research",
      description: "partner with zenith to implement bespoke process automation, and data-collection pipelines that improve operating margins and business scalability."
    }
  ]